import React, { useState, useRef, useEffect } from "react";
import {
  motion,
  useMotionValue,
  AnimatePresence,
  useTransform,
} from "framer-motion";
import {
  MessageSquare,
  Send,
  Scale,
  Scroll,
  Minimize,
  Plus,
  ZoomIn,
  ZoomOut,
  MessageCircle,
  Link2,
  Lightbulb,
} from "lucide-react";
import axios from "axios";
import { apiUri } from "../../constants";
import CardSelector from "../../components/Canvas/SelectingCards";

interface Suggestion {
  title: string;
  description: string;
  type: string;
  confidence: number;
}


interface Response {
  id: number;
  query: string;
  text: string;
  position: {
    x: number;
    y: number;
  };
  isExpanded: boolean;
  showContinueInput?: boolean;
  continuedConversations?: {
    query: string;
    response: string;
    id: number;
    suggestions?: Suggestion[];
  }[];
  suggestions?: Suggestion[];
}


interface Position {
  x: number;
  y: number;
}
const InfiniteCanvas = () => {
  const [responses, setResponses] = useState<Response[]>([]);
  const [input, setInput] = useState("");
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const [scale, setScale] = useState(0.8);

  const [canvasId, setCanvasId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const canvasRef = useRef<HTMLDivElement>(null);

  const [currentResponseText, setCurrentResponseText] = useState("");
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  );

  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const currentTextRef = useRef("");

  const [canvasDimensions, setCanvasDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Transform values for zoom
  const scaleValue = useTransform(useMotionValue(scale), [0.5, 3], [0.5, 3]);
const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [occupiedPositions, setOccupiedPositions] = useState<Set<string>>(
    new Set()
  );

  // Function to check if a position is occupied
  const isPositionOccupied = (x: number, y: number): boolean => {
    return occupiedPositions.has(`${x},${y}`);
  };

  const findNextPosition = (
    startX: number = 0,
    startY: number = 0
  ): Position => {
    const spacing = 500; // Space between cards
    const spiralCoeff = 500; // Coefficient for spiral movement
    let angle = 0;
    let radius = spacing;

    // Start from the last card's position
    let testX = startX;
    let testY = startY;

    // Try positions in a spiral pattern
    while (isPositionOccupied(testX, testY)) {
      // Calculate next position using parametric equations of a spiral
      angle += 0.5; // Increment angle
      radius = spiralCoeff * (angle / (2 * Math.PI));
      testX = startX + radius * Math.cos(angle);
      testY = startY + radius * Math.sin(angle);
    }

    // Update occupied positions
    setOccupiedPositions((prev) => {
      const next = new Set(prev);
      next.add(`${testX},${testY}`);
      return next;
    });

    return { x: testX, y: testY };
  };
  // Card colors
  const cardColors = [
    { bg: "bg-rose-50", border: "border-rose-200", icon: "text-rose-600" },
    { bg: "bg-sky-50", border: "border-sky-200", icon: "text-sky-600" },
    { bg: "bg-amber-50", border: "border-amber-200", icon: "text-amber-600" },
    {
      bg: "bg-emerald-50",
      border: "border-emerald-200",
      icon: "text-emerald-600",
    },
    {
      bg: "bg-violet-50",
      border: "border-violet-200",
      icon: "text-violet-600",
    },
    { bg: "bg-teal-50", border: "border-teal-200", icon: "text-teal-600" },
  ];

  // Click away handler
  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (!e.target) return;

      // Check if click is on a card
      const isCard = (e.target as Element).closest(
        '[data-type="response-card"]'
      );
      if (!isCard) {
        setResponses((prev) =>
          prev.map((response) => ({
            ...response,
            isExpanded: false,
          }))
        );
      }
    };

    document.addEventListener("click", handleClickAway);
    return () => document.removeEventListener("click", handleClickAway);
  }, []);

  useEffect(() => {
    if (currentResponseId && currentTextRef.current) {
      setResponses((prevResponses) =>
        prevResponses.map((r) =>
          r.id === currentResponseId
            ? { ...r, text: currentTextRef.current }
            : r
        )
      );
    }
  }, [currentResponseId, currentTextRef.current]);

  // Zoom handlers
  const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.25, 3));
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.25, 0.5));
  };

  const handleWheel = (event: React.WheelEvent) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      const delta = -event.deltaY * 0.003;
      setScale((prev) => Math.min(Math.max(prev + delta, 0.5), 3));
    }
  };

  const navigateToPosition = (posX: number, posY: number) => {
    // Calculate the center of the viewport
    const viewportCenterX = window.innerWidth / 2;
    const viewportCenterY = window.innerHeight / 2;

    // Calculate the position to center the target
    const targetX = -posX + viewportCenterX;
    const targetY = -posY + viewportCenterY;

    // Animate to the new position
    x.set(targetX);
    y.set(targetY);
  };

  // Generate grid points
  const generateGridPoints = () => {
    const points = [];
    const spacing = 50;

    for (let i = 0; i * spacing <8000; i++) {
      for (let j = 0; j * spacing < 8000; j++) {
        points.push({ x: i * spacing, y: j * spacing });
      }
    }
    return points;
  };

  const toggleCardExpansion = (id: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setResponses(
      responses.map((response) => ({
        ...response,
        isExpanded: response.id === id ? !response.isExpanded : false,
      }))
    );
  };

  // Initialize canvas and load responses
  useEffect(() => {
    const initCanvas = async () => {
      try {
        setIsLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        let currentCanvasId = urlParams.get("canvasId");

        if (!currentCanvasId) {
          const response = await axios.post(`${apiUri}/canvas`, {
            name: `Canvas ${new Date().toLocaleString()}`,
          });
          currentCanvasId = response.data.id;
          window.history.pushState({}, "", `?canvasId=${currentCanvasId}`);
        }

        setCanvasId(currentCanvasId || "abc");

        // Load responses and position them
        const { data } = await axios.get(
          `${apiUri}/canvas/${currentCanvasId}/responses`
        );

        // console.log(data)
        // console.log("RAW DATA ABOVE =================")

        // Clear occupied positions
        setOccupiedPositions(new Set());

        // Sort responses by creation time to maintain consistent layout
        const sortedResponses = [...data];

        // Position each response
        const positionedResponses = sortedResponses.map((response) => {
          const position = findNextPosition();
          return {
            ...response,
            position,
          };
        });

        setResponses(data);

        // console.log(positionedResponses)

        // If there are responses, set the last position for new cards
        if (positionedResponses.length > 0) {
          const lastResponse =
            positionedResponses[positionedResponses.length - 1];
          setLastPosition(lastResponse.position);
        }
      } catch (error) {
        console.error("Failed to initialize canvas:", error);

        setResponses([]);
      } finally {
        setIsLoading(false);
      }
    };

    initCanvas();
  }, []);

  // Modified handleSubmit to save responses
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newPosition = findNextPosition(lastPosition.x, lastPosition.y);

    try {
      const responseId = Date.now();
      setCurrentResponseId(responseId);
      setCurrentResponseText("");
      currentTextRef.current = "";

      // Create initial empty response
      const newResponse: Response = {
        id: responseId,
        text: "",
        query: input,
        position: newPosition,
        isExpanded: false,
      };

      // Save initial response to database

      setResponses([...responses, newResponse]);

      // Create EventSource for SSE
      const eventSource = new EventSource(
        `${apiUri}/queryToModel?input_question=${encodeURIComponent(input)}`
      );

      setLastPosition(newPosition);
      setInput("");
      setIsInputExpanded(false);
      navigateToPosition(newPosition.x, newPosition.y);

      eventSource.onmessage = async (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data.status === "error") {
            console.error("Error from API:", data.response);
            eventSource.close();
            return;
          }

          // Use ref to accumulate text
          currentTextRef.current += data.response;

          // console.log(data.response);
          // Update response in state
          setResponses((prevResponses) =>
            prevResponses.map((r) =>
              r.id === responseId ? { ...r, text: currentTextRef.current } : r
            )
          );

          // If response is complete, save to database
          if (data.done) {
            eventSource.close();

            await axios.post(`${apiUri}/canvas/${canvasId}/responses`, {
              id: responseId,
              text: currentTextRef.current,
              query: input,
              position: newPosition,
            });
          }
        } catch (error) {
          console.error("Error processing SSE message:", error);
          eventSource.close();
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSource.close();
        setCurrentResponseId(null);
        currentTextRef.current = "";
      };
    } catch (error) {
      console.error("Error setting up response:", error);
      setCurrentResponseId(null);
      currentTextRef.current = "";
    }
  };

  // Modified handleContinueConversation to save continued responses
const handleContinueConversation = async (
  parentId: number,
  continueInput: string,
  pastMessages: { role: string; content: string }[]
) => {
  try {
    const responseId = Date.now();

    // Update UI immediately
    setResponses((prev) =>
      prev.map((r) => {
        if (r.id === parentId) {
          return {
            ...r,
            continuedConversations: [
              ...(r.continuedConversations || []),
              { query: continueInput, response: "", id: responseId },
            ],
          };
        }
        return r;
      })
    );

    // Set up streaming
    const queryString = new URLSearchParams({
      pastChats: JSON.stringify(pastMessages),
      newQuery: continueInput,
    }).toString();

    const eventSource = new EventSource(
      `${apiUri}/continueConvWithModel?${queryString}`
    );

    setCurrentResponseId(responseId);
    currentTextRef.current = "";

    eventSource.onmessage = async (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.status === "error") {
          console.error("Error from API:", data.response);
          eventSource.close();
          return;
        }

        currentTextRef.current += data.response;

        // Update UI
        setResponses((prev) =>
          prev.map((r) => {
            if (r.id === parentId) {
              const updatedConversations = [
                ...(r.continuedConversations || []),
              ];
              const convIndex = updatedConversations.findIndex(
                (c) => c.id === responseId
              );
              if (convIndex !== -1) {
                updatedConversations[convIndex] = {
                  ...updatedConversations[convIndex],
                  response: currentTextRef.current,
                };
              }
              return {
                ...r,
                continuedConversations: updatedConversations,
              };
            }
            return r;
          })
        );

        // If complete, save to database and fetch suggestions
        if (data.done) {
          eventSource.close();

          // Save to database
          await axios.post(
            `${apiUri}/canvas/${canvasId}/responses/${parentId}/continue`,
            {
              query: continueInput,
              response: currentTextRef.current,
              responseId,
            }
          );

          // Fetch suggestions
          try {
            const selectedResponse = {
              id: responseId,
              query: continueInput,
              text: currentTextRef.current,
              position: { x: Math.round(0), y: Math.round(0) }, // Ensure integers
            };
            setIsLoadingSuggestions(true)
            const suggestionEventSource = new EventSource(
              `${apiUri}/suggestions?selectedResponses=${encodeURIComponent(
                JSON.stringify([selectedResponse])
              )}`
            );

            suggestionEventSource.onmessage = (suggEvent) => {
              try {
                const suggData = JSON.parse(suggEvent.data);
                console.log("Received suggestion data:", suggData); // Debug log

                if (suggData.status === "error") {
                  console.error(
                    "Error fetching suggestions:",
                    suggData.response
                  );
                  suggestionEventSource.close();
                  return;
                }

                if (suggData.suggestions) {
                  // Update the response with suggestions
                  setResponses((prev) =>
                    prev.map((r) => {
                      if (r.id === parentId) {
                        const updatedConversations = [
                          ...(r.continuedConversations || []),
                        ];
                        const convIndex = updatedConversations.findIndex(
                          (c) => c.id === responseId
                        );
                        if (convIndex !== -1) {
                          updatedConversations[convIndex] = {
                            ...updatedConversations[convIndex],
                            suggestions: suggData.suggestions,
                          };
                        }
                        return {
                          ...r,
                          continuedConversations: updatedConversations,
                        };
                      }
                      return r;
                    })
                  );
                }

                if (suggData.done) {
                  suggestionEventSource.close();
                }
              } catch (error) {
                console.error("Error processing suggestion data:", error);
                suggestionEventSource.close();
              }
            };

            suggestionEventSource.onerror = (error) => {
              console.error("Suggestion EventSource failed:", error);
              suggestionEventSource.close();
              setIsLoadingSuggestions(false)
            };
          } catch (error) {
            console.error("Error setting up suggestions:", error);
          }
   setIsLoadingSuggestions(false);
          setCurrentResponseId(null);
        }
      } catch (error) {
        console.error("Error processing SSE message:", error);
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
      setCurrentResponseId(null);
      currentTextRef.current = "";
    };
  } catch (error) {
    console.error("Error setting up conversation continuation:", error);
  }
};

  const renderResponseCard = (response: Response, index: number) => {
    const colorIndex = index % cardColors.length;
    const colors = cardColors[colorIndex];

    return (
      <motion.div
        key={response.id}
        data-type="response-card"
        className={`absolute ${colors.bg} rounded-xl shadow-lg p-6 border ${colors.border} cursor-move`}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{
          opacity: 1,
          scale: response.isExpanded ? 1.05 : 1,
          width: response.isExpanded ? 600 : 500,
          x: response.position.x,
          y: response.position.y,
        }}
        drag
        dragMomentum={false}
        onDragEnd={(event, info) => {
          const newPosition = {
            x: response.position.x + info.offset.x,
            y: response.position.y + info.offset.y,
          };
          setResponses((prev) =>
            prev.map((r) =>
              r.id === response.id ? { ...r, position: newPosition } : r
            )
          );
        }}
        transition={{ type: "spring", stiffness: 100 }}
        onClick={(e) => toggleCardExpansion(response.id, e)}
        whileHover={{ scale: response.isExpanded ? 1.05 : 1.02 }}
      >
        <button
          onClick={() => setIsSelectorOpen(true)}
          className="mb-1 flex items-center gap-2 px-4 py-2 bg-white rounded-lg border border-gray-200 hover:bg-gray-50"
        >
          <Link2 className="w-4 h-4" />
          Link Responses
        </button>
        <div className="flex items-center gap-3 mb-4">
          <Scroll className={`w-6 h-6 ${colors.icon}`} />
          <h3 className="font-semibold text-gray-900 text-lg">
            {response.query}
          </h3>
        </div>

        <div className="space-y-4">
          {/* Main response */}
          <div className="p-4 rounded-lg bg-white/80 backdrop-blur-sm">
            <p className="text-gray-700 leading-relaxed whitespace-pre-wrap">
              {response.text}
            </p>
          </div>

          {/* Suggestions section */}
          <div className="mt-4 space-y-2">
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Lightbulb className="w-4 h-4" />
              <span>Suggested follow-ups:</span>
            </div>
            <div className="space-y-2">
              {isLoadingSuggestions ? (
                <div className="text-sm text-gray-500 p-3 rounded-lg bg-white border border-gray-200">
                  Generating suggestions...
                </div>
              ) : response.suggestions && response.suggestions.length > 0 ? (
                response.suggestions.map((suggestion, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      const textArea = document.querySelector(
                        '[name="continueInput"]'
                      ) as HTMLTextAreaElement;
                      if (textArea) {
                        textArea.value = suggestion.description;
                        textArea.focus();
                      }
                    }}
                    className="w-full text-left p-3 rounded-lg bg-white hover:bg-gray-50 border border-gray-200"
                  >
                    <div className="font-medium text-sm">
                      {suggestion.title}
                    </div>
                    <div className="text-sm text-gray-600 mt-1">
                      {suggestion.description}
                    </div>
                    <div className="text-xs text-gray-400 mt-1">
                      Confidence: {Math.round(suggestion.confidence * 100)}%
                    </div>
                  </button>
                ))
              ) : (
                <div className="text-sm text-gray-500 p-3 rounded-lg bg-white border border-gray-200">
                  No suggestions available
                </div>
              )}
            </div>
          </div>
          
          {/* Continued conversations */}
          {response.continuedConversations?.map((conv, idx) => (
            <div key={conv.id} className="ml-6 border-l-2 border-gray-200 pl-4">
              <div className="text-sm font-medium text-gray-500 mb-2">
                Follow-up: {conv.query}
              </div>
              <div className="p-4 rounded-lg bg-white/80 backdrop-blur-sm">
                <p className="text-gray-700 leading-relaxed whitespace-pre-wrap">
                  {conv.response}
                </p>
              </div>
            </div>
          ))}

          {/* Continue conversation button and form */}
          <div className="mt-4">
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleContinueInput(response.id);
              }}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm ${
                colors.icon
              } hover:bg-white/50 transition-colors ${
                response.showContinueInput ? "bg-white/50" : ""
              }`}
            >
              <MessageCircle className="w-4 h-4" />
              Continue Conversation
            </button>

            {response.showContinueInput && (
              <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={(e) => {
                  e.preventDefault();
                  const input = e.currentTarget.continueInput.value;
                  if (!input.trim()) return;

                  const buildConversationHistory = (): {
                    role: string;
                    content: string;
                  }[] => {
                    const history = [
                      { role: "user", content: response.query },
                      { role: "assistant", content: response.text },
                    ];

                    response.continuedConversations?.forEach((conv) => {
                      history.push(
                        { role: "user", content: conv.query },
                        { role: "assistant", content: conv.response }
                      );
                    });

                    return history;
                  };

                  handleContinueConversation(
                    response.id,
                    input,
                    buildConversationHistory()
                  );
                  e.currentTarget.continueInput.value = "";
                }}
                className="mt-4 space-y-2"
              >
                <textarea
                  name="continueInput"
                  className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 resize-none"
                  placeholder="Continue the conversation..."
                  rows={3}
                />
                <button
                  type="submit"
                  className={`${colors.bg} ${colors.border} px-4 py-2 rounded-lg text-sm font-medium hover:bg-white/50 transition-colors flex items-center gap-2`}
                >
                  <Send className="w-4 h-4" />
                  Send
                </button>
              </form>
            )}
          </div>
        </div>
      </motion.div>
    );
  };

  const toggleContinueInput = (responseId: number) => {
    setResponses((prevResponses) =>
      prevResponses.map((r) =>
        r.id === responseId
          ? { ...r, showContinueInput: !r.showContinueInput }
          : r
      )
    );
  };

  return (
    <div
      className="fixed inset-0 w-screen h-screen overflow-hidden bg-gray-50"
      onWheel={handleWheel}
    >
      {/* Header */}

      <div className="fixed top-4 left-4 bg-white/95 p-4 rounded-xl shadow-lg z-50 text-sm border border-gray-200">
        <div className="flex items-center gap-3">
          <Scale className="w-5 h-5 text-gray-700" />
          <div>
            <h3 className="font-semibold text-gray-900">Infinite Canvas</h3>
            <p className="text-gray-600">
              {responses.length} responses • Drag to navigate
            </p>
          </div>
        </div>
        <div className="fixed top-4 left-4 bg-white/95 p-4 rounded-xl shadow-lg z-50 text-sm border border-gray-200">
          <div className="flex items-center gap-3">
            <Scale className="w-5 h-5 text-gray-700" />
            <div>
              <h3 className="font-semibold text-gray-900">Infinite Canvas</h3>
              <p className="text-gray-600">
                {responses.length} responses • Drag to navigate
              </p>
            </div>
            {responses.length > 0 && (
              <button
                onClick={() => {
                  const lastResponse = responses[responses.length - 1];
                  navigateToPosition(
                    lastResponse.position.x,
                    lastResponse.position.y
                  );
                }}
                className="ml-4 px-3 py-1.5 bg-gray-100 hover:bg-gray-200 rounded-lg text-sm font-medium text-gray-700 flex items-center gap-2"
              >
                <MessageSquare className="w-4 h-4" />
                Go to Latest
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Zoom Controls */}
      <div className="fixed top-4 right-4 bg-white/95 p-2 rounded-xl shadow-lg z-50 flex gap-2 border border-gray-200">
        <button
          onClick={handleZoomIn}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          title="Zoom In (Ctrl/Cmd + Scroll Up)"
        >
          <ZoomIn className="w-5 h-5 text-gray-700" />
        </button>
        <div className="flex items-center px-2 border-l border-r border-gray-200">
          <span className="text-sm text-gray-600">
            {Math.round(scale * 100)}%
          </span>
        </div>
        <button
          onClick={handleZoomOut}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          title="Zoom Out (Ctrl/Cmd + Scroll Down)"
        >
          <ZoomOut className="w-5 h-5 text-gray-700" />
        </button>
      </div>

      {/* Main Canvas */}
      <motion.div
        ref={canvasRef}
        className="relative bg-gray-50"
        style={{
          width: "8000px",
          height: "8000px",
          x,
          y,
          scale: scale,
          transformOrigin: "0 0",
        }}
        drag
        dragMomentum={false}
        dragElastic={0}
      >
        {/* Grid pattern */}
        {generateGridPoints().map((point, index) => (
          <div
            key={index}
            className="absolute w-1 h-1 bg-gray-200 rounded-full"
            style={{
              left: point.x,
              top: point.y,
            }}
          />
        ))}

        {/* Response Cards */}
        {responses.map((response, index) =>
          renderResponseCard(response, index)
        )}
      </motion.div>

      {isSelectorOpen && (
        <CardSelector
          isOpen={isSelectorOpen}
          responses={responses}
          onClose={() => setIsSelectorOpen(false)}
          findNextPosition={findNextPosition}
          setResponses={setResponses}
          setLastPosition={setLastPosition}
          navigateToPosition={navigateToPosition}
        />
      )}

      {/* Fixed Input Area */}
      <AnimatePresence>
        <motion.div
          className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50"
          initial={false}
          animate={isInputExpanded ? { y: 0 } : { y: 280 }}
        >
          <div className="bg-white rounded-xl shadow-xl border border-gray-200 overflow-hidden w-[600px]">
            <button
              onClick={() => setIsInputExpanded(!isInputExpanded)}
              className="w-full py-3 px-4 bg-gray-50 text-gray-700 flex items-center justify-center gap-2 hover:bg-gray-100 transition-colors font-medium"
            >
              {isInputExpanded ? (
                <>
                  <Minimize className="w-4 h-4" />
                  Minimize Query Input
                </>
              ) : (
                <>
                  <Plus className="w-4 h-4" />
                  New Query
                </>
              )}
            </button>

            <form onSubmit={handleSubmit} className="p-6">
              <div className="flex flex-col gap-4">
                <textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 min-h-[150px] text-gray-700 resize-none"
                  placeholder="Enter your query..."
                />
                <button
                  type="submit"
                  className="bg-gray-900 text-white px-6 py-3 rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 flex items-center justify-center gap-2 font-medium"
                >
                  <Send className="w-5 h-5" />
                  Submit Query
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default InfiniteCanvas;
