import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ChevronDown, Menu, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Header: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mouseLeftName, setMouseLeftName] = useState(true);
  const [mouseLeftBody, setMouseLeftBody] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    setIsLoggedIn(!!storedEmail);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };

  useEffect(() => {
    if (!mouseLeftBody || !mouseLeftName) {
      gsap.to(dropdownRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power1.inOut",
        display: "block",
      });
    } else {
      gsap.to(dropdownRef.current, {
        opacity: 0,
        y: -20,
        duration: 0.5,
        ease: "power1.inOut",
        display: "none",
      });
    }
  }, [mouseLeftBody, mouseLeftName]);

  return (
    <header ref={headerRef}>
      <div className="bg-[#48483A] w-full border-b border-white text-white shadow-xl py-4">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <div
              onClick={() => {
                window.location.href = "/";
              }}
              className="cursor-pointer"
            >
              <img src="/logo.png" alt="Logo" className="w-16 mr-4" />
            </div>
          </div>

          <div className="lg:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="text-white hover:text-creamyYellow transition duration-300 focus:outline-none"
            >
              {mobileMenuOpen ? (
                <X className="w-8 h-8" />
              ) : (
                <Menu className="w-8 h-8" />
              )}
            </button>
          </div>

          <nav className="hidden lg:flex items-center space-x-6">
            <a
              href="/canvas"
              className="text-xl font-semibold rounded-full hover:text-creamyYellow transition duration-300"
            >
              COLLAB CANVAS 🎨
            </a>

            <a
              href="/lawpotato"
              className="text-xl font-semibold rounded-full hover:text-creamyYellow transition duration-300"
            >
              Take Me There 🚀
            </a>

            <div className="relative">
              <div
                className="relative group text-xl font-semibold hover:text-creamyYellow transition duration-300 flex items-center cursor-pointer"
                onMouseEnter={() => setMouseLeftName(false)}
                onMouseLeave={() => setMouseLeftName(true)}
              >
                More{" "}
                <ChevronDown
                  className={`ml-1 transition-transform duration-300 ${
                    dropdownOpen ? "rotate-180" : ""
                  }`}
                />
              </div>

              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg py-2 z-20 hidden lg:block"
                onMouseLeave={() => setMouseLeftBody(true)}
                onMouseEnter={() => setMouseLeftBody(false)}
              >
                {[
                  { name: "Contact Us", link: "/contactUs" },
                  { name: "Privacy Policy", link: "/privacy" },
                  { name: "Terms & Conditions", link: "/terms" },
                ].map((item) => (
                  <a
                    key={item.name}
                    href={item.link}
                    className="block px-4 py-2 text-md hover:bg-creamyYellow transition duration-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>

            {isLoggedIn ? (
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-red-600 transition duration-300"
              >
                Logout
              </button>
            ) : (
              <a
                href="/login"
                className="bg-creamyYellow text-black text-lg font-bold py-2 px-6 rounded-full hover:bg-yellow-100 transition duration-300"
              >
                Login
              </a>
            )}
          </nav>
        </div>

        {mobileMenuOpen && (
          <nav className="lg:hidden flex flex-col space-y-2 px-4 py-2 bg-[#48483A]">
            <a
              href="/lawpotato"
              className="block text-xl text-white font-semibold rounded-full hover:text-creamyYellow transition duration-300"
            >
              Take Me There 🚀
            </a>

            <div className="relative group">
              <button
                className="block text-xl text-white font-semibold hover:text-creamyYellow transition duration-300"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                More{" "}
                <ChevronDown
                  className={`ml-1 inline-block transition-transform duration-300 ${
                    dropdownOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {dropdownOpen && (
                <div className="mt-2 w-full bg-white text-black rounded-lg shadow-lg py-2">
                  {[
                    { name: "Contact Us", link: "/contactUs" },
                    { name: "Privacy Policy", link: "/privacy" },
                    { name: "Terms & Conditions", link: "/terms" },
                  ].map((item) => (
                    <a
                      key={item.name}
                      href={item.link}
                      className="block px-4 py-2 text-md hover:bg-creamyYellow transition duration-300"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              )}
            </div>

            {isLoggedIn ? (
              <button
                onClick={handleLogout}
                className="bg-red-500 text-center text-white mt-2 text-lg w-1/4 font-bold py-2 px-6 rounded-full hover:bg-red-600 transition duration-300"
              >
                Logout
              </button>
            ) : (
              <a
                href="/login"
                className="bg-creamyYellow text-center text-black text-lg w-1/4 font-bold py-2 px-6 rounded-full hover:bg-yellow-100 transition duration-300"
              >
                Login
              </a>
            )}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
