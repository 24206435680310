import React, { useState } from "react";
import { X, Link2, MessageSquare } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { apiUri } from "../../constants";

interface Response {
  id: number;
  query: string;
  text: string;
  position: {
    x: number;
    y: number;
  };
  isExpanded: boolean;
  continuedConversations?: {
    query: string;
    response: string;
    id: number;
  }[];
}

interface CardSelectorProps {
  responses: Response[];
  onClose: () => void;
  
  findNextPosition: () => { x: number; y: number };
  setResponses: React.Dispatch<React.SetStateAction<Response[]>>;
  setLastPosition: React.Dispatch<
    React.SetStateAction<{ x: number; y: number }>
  >;
  navigateToPosition: (x: number, y: number) => void;
  isOpen: boolean;
}

const CardSelector: React.FC<CardSelectorProps> = ({
  responses,
  onClose,
  findNextPosition,
  setResponses,
  setLastPosition,
  navigateToPosition,
  isOpen,
}) => {
  const [selectedCards, setSelectedCards] = useState<Set<number>>(new Set());
  const [linkQuery, setLinkQuery] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleCardSelection = (id: number) => {
    const newSelection = new Set(selectedCards);
    if (selectedCards.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedCards(newSelection);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!linkQuery.trim() || selectedCards.size === 0) return;

    setIsSubmitting(true);
    const selectedResponses = Array.from(selectedCards).map((id) => {
      const response = responses.find((r) => r.id === id);
      return {
        id: response?.id,
        query: response?.query,
        text: response?.text,
        position: response?.position,
        continuedConversations: response?.continuedConversations?.map(
          (conv) => ({
            id: conv.id,
            query: conv.query,
            response: conv.response,
          })
        ),
      };
    });

    try {
      const newPosition = findNextPosition();
      const responseId = Date.now();

      setResponses((prev) => [
        ...prev,
        {
          id: responseId,
          query: linkQuery,
          text: "",
          position: newPosition,
          isExpanded: false,
        },
      ]);

      setLastPosition(newPosition);
      navigateToPosition(2000 + newPosition.x, 2000 + newPosition.y);

      const queryString = new URLSearchParams({
        selectedResponses: JSON.stringify(selectedResponses),
        query: linkQuery,
      }).toString();

      const eventSource = new EventSource(
        `${apiUri}/processLinkedCards?${queryString}`
      );

      let accumulatedText = "";

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.status === "error") {
          console.error("Error from API:", data.response);
          eventSource.close();
          return;
        }

        accumulatedText += data.response;

        setResponses((prev) =>
          prev.map((r) =>
            r.id === responseId ? { ...r, text: accumulatedText } : r
          )
        );

        if (data.done) {
          eventSource.close();
          setIsSubmitting(false);
          onClose();
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSource.close();
        setIsSubmitting(false);
      };
    } catch (error) {
      console.error("Error processing linked cards query:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.9, y: 20 }}
            className="w-full max-w-2xl bg-white rounded-xl shadow-2xl overflow-hidden"
          >
            <div className="p-4 bg-gradient-to-r from-blue-600 to-blue-700 border-b flex justify-between items-center">
              <motion.h2
                initial={{ x: -20 }}
                animate={{ x: 0 }}
                className="text-xl font-bold text-white flex items-center gap-2"
              >
                <Link2 className="w-5 h-5" />
                Link Responses
              </motion.h2>
              <motion.button
                whileHover={{ rotate: 90 }}
                whileTap={{ scale: 0.9 }}
                onClick={onClose}
                className="p-2 rounded-full hover:bg-white/10 transition-colors"
                disabled={isSubmitting}
              >
                <X className="h-5 w-5 text-white" />
              </motion.button>
            </div>

            <div className="p-6 space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-sm text-gray-600"
              >
                {selectedCards.size} cards selected
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="max-h-[300px] overflow-y-auto space-y-2 border border-gray-200 rounded-lg p-4"
              >
                {responses.map((response, index) => (
                  <motion.div
                    key={response.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.05 }}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      selectedCards.has(response.id)
                        ? "bg-blue-50 border-blue-200 scale-[1.02]"
                        : "bg-gray-50 border-gray-200 hover:bg-gray-100"
                    } border`}
                    onClick={() => toggleCardSelection(response.id)}
                  >
                    <div className="flex items-center gap-2">
                      <MessageSquare
                        className={`w-4 h-4 ${
                          selectedCards.has(response.id)
                            ? "text-blue-500"
                            : "text-gray-500"
                        }`}
                      />
                      <span className="font-medium text-gray-900">
                        {response.query}
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-600 line-clamp-2">
                      {response.text}
                    </p>
                  </motion.div>
                ))}
              </motion.div>

              <motion.form
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                onSubmit={handleSubmit}
                className="space-y-4"
              >
                <textarea
                  value={linkQuery}
                  onChange={(e) => setLinkQuery(e.target.value)}
                  placeholder="Ask a question about the selected responses..."
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px] text-gray-700 resize-none"
                  disabled={isSubmitting}
                />

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  disabled={
                    selectedCards.size === 0 ||
                    !linkQuery.trim() ||
                    isSubmitting
                  }
                  className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2 font-medium"
                >
                  <Link2 className="w-5 h-5" />
                  {isSubmitting ? "Processing..." : "Create Linked Response"}
                </motion.button>
              </motion.form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CardSelector;
